import { Button, Typography } from "antd";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "./header.css";
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Offcanvas from 'react-bootstrap/Offcanvas';
import LogoutIcon from '@mui/icons-material/Logout';
import StorageService from "../../data/storage/storage-service";
import { confirmAlert } from 'react-confirm-alert';
import logo from "../../assets/Veedu-App-Web-Logo.png"
import Dropdown from 'react-bootstrap/Dropdown';
import AuthApiService from "../../data/api/services/auth/auth-api-service";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons'
import Avatar from "../../assets/profile-pic.jpeg"
import SwitchAccount from "../../assets/switch-account-icon.jpeg"
import SwitchAccountIcon from '@mui/icons-material/SwitchAccount';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import AddIcon from '@mui/icons-material/Add';
import { Col, Modal, Row } from "react-bootstrap";
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import ProfessionalPageApiService from "../../data/api/services/professional-page/professional-page-api-service";
import PersonIcon from '@mui/icons-material/Person';
import LoginPageDialog from "../../pages/login/login-page-dialog";

const Header = () => {

  let navigate: any = useNavigate();
  const [showLoggedOutBtn, setShowLoggedOutBtn] = useState(false);
  const [userData, setUserData] = useState('');
  const authService: AuthApiService = new AuthApiService();

  const professionalPageApiService = new ProfessionalPageApiService();
  const [data, setData] = useState<any>([]);
  const [isSubMenuVisible, setSubMenuVisible] = useState(false);
  const [isSwitchAccountMenuVisible, setSwitchAccountMenuVisible] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);

  const [showLogInDialog, setShowLogInDialog] = useState(false);
  const handleShowLogInDialog = () => setShowLogInDialog(true);
  const handleCloseLogInDialog = () => setShowLogInDialog(false);

  const activePageItem = data.find(item => item.activePage === true);

  const handleRadioChange = (item, index) => {
    setSelectedItem(index);
    switchProfessionalPage(item.id, index);
  };

  const handleImageClick = () => {
    setSubMenuVisible(!isSubMenuVisible);
    setSwitchAccountMenuVisible(false);
  };

  const handleSwitchAccountClick = () => {
    setSwitchAccountMenuVisible(!isSwitchAccountMenuVisible);
    setSubMenuVisible(false);
  };

  const closeSubMenu = () => {
    if (isSubMenuVisible) {
      setSubMenuVisible(false);
      setSwitchAccountMenuVisible(false);
    }
    if (isSwitchAccountMenuVisible) {
      setSubMenuVisible(false);
      setSwitchAccountMenuVisible(false);
    }
  };

  const resArr = [
    {
      'name': 'Sign Up',
      'path': '/register'

    },
  ];

  const dropdown = [

    {
      'title': 'Pages',
      'active': true,
      'code': 'page',
      'subMenues': [
        {
          'subName': 'My Pages',
          'active': true,
          'code': 'myProfessionalPages',
        },
        {
          'subName': 'Professionals',
          'active': true,
          'code': 'allProfessionalPages',
          'path': '/professionals'
        },
        // {
        //   'subName': 'Tags',
        //   'active': true,
        //   'code': 'allTags',
        //   'path': '/tags'
        // },
        // {
        //   'subName': 'FloorPlan',
        //   'active': true,
        //   'code': 'floorPlan',
        //   'path': '/plans'
        // },
      ],
    },
    // {
    //   'title': 'Loan',
    //   'active': true,
    //   'code': 'loan',
    //   'subMenues': [
    //     {
    //       'subName': 'Apply For Loan',
    //       'active': true,
    //       'code': 'applyForLoan',
    //       'path': '/loan'
    //     },
    //     {
    //       'subName': 'Track Loan',
    //       'active': true,
    //       'code': 'trackLoan',
    //       'path': '/loan-status'
    //     }
    //   ],
    // },
    {
      'title': 'Pricing',
      'active': true,
      'code': 'pricing',
      'subMenues': [
        {
          'subName': 'Plans & Pricing',
          'active': true,
          'code': 'planPrice',
          'path': '/pricing'
        },
      ],
    },
  ]

  const doLogout = () => {
    StorageService.clear();
    navigate('/login');
  };

  const showLoggoutButton = () => {
    if (StorageService.isLoggedIn()) {
      setShowLoggedOutBtn(true);
    }
  };

  const showLoggedInConfirmDialog = () => {
    confirmAlert({
      title: 'Alert',
      message: 'You have to log in before adding property',
      buttons: [
        {
          label: 'Yes',
          onClick: () => { navigate('/login'); }
        },
        {
          label: 'No',
          onClick: () => { console.log('clicked no') }
        }
      ]
    });
  };

  const showLogInConfirmDialogToCreateProfessionalPage = () => {
    confirmAlert({
      title: 'Alert',
      message: 'You have to log in before creating professional page',
      buttons: [
        {
          label: 'Yes',
          onClick: () => { navigate('/login'); }
        },
        {
          label: 'No',
          onClick: () => { console.log('clicked no') }
        }
      ]
    });
  };

  const goToAddPropertyPage = () => {
    let user = StorageService.isLoggedIn();
    if (!user) {
      // showLoggedInConfirmDialog();
      handleShowLogInDialog();
    } else {
      navigate('/add-property');
    }
  };

  const goToCreateProfessionalPage = () => {
    let user = StorageService.isLoggedIn();
    if (!user) {
      handleShowLogInDialog();
      // showLogInConfirmDialogToCreateProfessionalPage();
    } else {
      navigate('/create-professional-page');
    }
  };

  const checkProxyUser = async () => {
    let token = StorageService.getToken();
    try {
      let response = await authService.doCheckProxyUser();

      let url = response + "?token=" + token;
      if (response) {
        window.open(url, '_blank');
      }
      console.log(response);
      setUserData(response);
    } catch (error) {
      console.log(error);
    }
  };

  const goToAllProfessionalPages = () => {
    let user = StorageService.isLoggedIn();
    if (!user) {
      navigate('/login');
      showLoggedInConfirmDialogToViewProfessionalPage();
    } else {
      navigate('/professionals');
    }
  };

  const goToMyProfessionalPages = () => {
    let user = StorageService.isLoggedIn();
    if (!user) {
      handleShowLogInDialog();
      // showLoggedInConfirmDialogToViewProfessionalPage();
    } else {
      navigate('/my-professional-pages');
    }
  };

  const handleSubMenuClick = (code) => {
    if (code === 'myProfessionalPages') {
      goToMyProfessionalPages();
    }
  };

  const showLoggedInConfirmDialogToViewProfessionalPage = () => {
    confirmAlert({
      title: 'Alert',
      message: 'You have to log in before viewing professional page',
      buttons: [
        {
          label: 'Yes',
          onClick: () => { navigate('/login'); }
        },
        {
          label: 'No',
          onClick: () => { console.log('clicked no') }
        }
      ]
    });
  };

  const getProfessionalPageDetails = async () => {
    try {
      const userId = StorageService.getUserId();
      console.log(userId);
      let res = await professionalPageApiService.getProfessionalPageData(userId);
      res.sort((a, b) => (b.activePage ? 1 : 0) - (a.activePage ? 1 : 0));
      setData(res);
    } catch (error) {
      console.log(error);
    }
  };

  const switchProfessionalPage = async (id, index) => {
    try {
      await professionalPageApiService.switchProfessionalPage(id);
      console.log(id);
      window.location.reload();
    } catch (error) {
      console.log(error)
    }
  }

  const handleProfileClick = (userId: number, id: number) => {
    // navigate(`/view-profession-profile?userId=${userId}&id=${id}`);
    const combined = `userId=${userId}&id=${id}`;
    const encoded = btoa(combined);
    navigate(`/professional?v=${encoded}`);
    console.log(`View button clicked for userId: ${userId}, id: ${id}`);
  };

  useEffect(() => {
    showLoggoutButton();
    getProfessionalPageDetails();
  }, []);

  useEffect(() => {
    document.addEventListener('click', closeSubMenu);

    return () => {
      document.removeEventListener('click', closeSubMenu);
    };
  }, [isSubMenuVisible]);

  return (
    <>
      {['md'].map((expand, idx) => (
        <Navbar expand={expand} className="header " key={'parent_menu_' + idx}>

          <Container className="" >
            <Navbar.Brand className="logo" href="/"><img src={logo} width={175} height={60} /></Navbar.Brand>
            <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-${expand}`} />
            <Navbar.Offcanvas
              id={`offcanvasNavbar-expand-${expand}`}
              aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
              placement="end"
              className="brand"
            >
              <Offcanvas.Header closeButton>
                <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${expand}`}>
                  {
                    (StorageService.getUserName()) && <Nav.Link className="me-3 welcome">
                      <b>  Welcome {StorageService.getUserName()} ! </b>
                    </Nav.Link>
                  }
                </Offcanvas.Title>
              </Offcanvas.Header>
              <Offcanvas.Body className="justify-content-between">
                <div >
                  <Nav className="justify-content-end flex-grow-1 pe-3">
                    {dropdown.map((menu, index) =>
                      <NavDropdown className="w-auto me-3"
                        title={menu.title}
                        key={'submenu_' + index}
                        id={`offcanvasNavbarDropdown-expand-${expand}`}
                      >
                        {menu.subMenues && menu.subMenues.map((subMenu, index) =>
                          <NavDropdown.Item className="w-100" href={subMenu.path} key={'submenu_' + index}
                            onClick={() => handleSubMenuClick(subMenu.code)}>{subMenu.subName}</NavDropdown.Item>
                        )}
                      </NavDropdown>
                    )}
                    {resArr.map((menus, index) =>
                      !showLoggedOutBtn &&
                      <Nav.Link className="me-3" href={menus.path} key={'menu_' + index}>{menus.name}</Nav.Link>
                    )}
                  </Nav>
                </div>
                <div>
                  <div className="d-sm-flex flex-sm-row">
                    <div>
                      {
                        (StorageService.getUserName()) && <Nav.Link className="m-2" onClick={checkProxyUser}>
                          <p><FontAwesomeIcon icon={faExternalLinkAlt} /> Welcome {StorageService.getUserName()}! </p>

                        </Nav.Link>
                      }
                    </div>
                    <Dropdown className="w-auto me-2">
                      <Dropdown.Toggle variant="success" id="dropdown-basic">
                        Add new
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        <Dropdown.Item >
                          <a className="pro me-3" href="javascript:void(0)" onClick={goToAddPropertyPage}>
                            <img src="https://resido.thesky9.com/themes/resido/img/submit.svg" width="20" alt="" className="me-2" />
                            Add Property
                          </a>
                        </Dropdown.Item>

                        <Dropdown.Item >
                          <a className="pro me-3" href="javascript:void(0)" onClick={goToCreateProfessionalPage}>
                            <img src="https://resido.thesky9.com/themes/resido/img/submit.svg" width="20" alt="" className="me-2" />
                            Create Professional Page
                          </a>
                        </Dropdown.Item>

                      </Dropdown.Menu>

                    </Dropdown>

                    {
                      !showLoggedOutBtn && <Button className="signin-btn ms-2 me-3" href="/login">
                        Sign In
                      </Button>
                    }
                    {/* <div className="logout">
                      {
                        showLoggedOutBtn && <Button className="signin-btn m-auto w-auto" onClick={doLogout}>
                          <LogoutIcon />
                        </Button>
                      }
                    </div> */}
                    {/* <div className="menus m-2">
                            <div>
                                <Nav.Link href="#action1">
                                  {
                                    (StorageService.getUserName() ) && <Nav.Link className="me-3 welcome">
                                      <b>  Welcome {StorageService.getUserName()} ! </b>
                                        </Nav.Link>
                                    }
                                </Nav.Link>
                            </div>
                                <div className="sub-menus">
                                  <div className='nav-link'>
                                    <Nav.Link className='aaa' href="#action1" >
                                    {
                                      showLoggedOutBtn && <label onClick={doLogout}>
                                        Logout
                                      <LogoutIcon />
                                    </label> 
                                    }
                                    </Nav.Link>
                                </div>
                                </div>
                        </div>   */}
                  </div>
                </div>
              </Offcanvas.Body>
            </Navbar.Offcanvas>

            {StorageService.isLoggedIn() && (
              <div className="mb-2 ms-2" onClick={(e) => e.stopPropagation()}>
                <a className="header-user-pic-border">
                  <img
                    className="header-user-pic"
                    // src={activePageItem ? activePageItem.profileImageUrl : Avatar}
                    src={activePageItem && activePageItem.profileImageUrl ? activePageItem.profileImageUrl : Avatar}
                    alt="profile"
                    onClick={handleImageClick}
                  />
                </a>
                {isSubMenuVisible && (
                  <div className="header-user-pic-sub-menu-wrap">
                    <div className="header-user-pic-sub-menu">
                      <Row className="user-info">
                        <Col md={3} xs={3}
                          onClick={() => handleProfileClick(activePageItem.createdBy, activePageItem.id)}>
                          <img src={activePageItem && activePageItem.profileImageUrl ? activePageItem.profileImageUrl : Avatar} />
                        </Col>
                        <Col md={6} xs={6} className="p-0"
                          onClick={() => handleProfileClick(activePageItem.createdBy, activePageItem.id)}>
                          <span style={{ fontWeight: '600' }}>{activePageItem ? activePageItem.name : "*****"}</span>
                          {/* <a style={{ fontSize: 'smaller', color: '#0d6efd', cursor: 'pointer' }}>{"View your page"}</a> */}
                        </Col>
                        <Col md={3} xs={3}
                          onClick={handleSwitchAccountClick}>
                          <img style={{ borderRadius: '10px' }}
                            src={SwitchAccount} />
                        </Col>
                      </Row>
                      <Col className="d-flex justify-content-center">
                        <span style={{ fontSize: 'smaller', color: '#0d6efd', cursor: 'pointer' }}
                          onClick={() => handleProfileClick(activePageItem.createdBy, activePageItem.id)}>
                          {"View your page"}
                        </span>
                        <span className="mx-1">·</span>
                        <span style={{ fontSize: 'smaller', color: '#0d6efd', cursor: 'pointer' }}>
                          {"Inbox"}
                        </span>
                      </Col>
                      <hr className="mt-0" />
                      <div className="sub-menu-link mb-2" onClick={handleSwitchAccountClick}>
                        <SwitchAccountIcon className="p-2 me-2"
                          style={{ width: '35px', height: '35px', background: '#e5e5e5', borderRadius: '50%' }} />
                        <p>Switch Account</p>
                        <span><ChevronRightIcon /></span>
                      </div>
                      <div className="sub-menu-link mb-2" onClick={goToMyProfessionalPages}>
                        <PersonIcon className="p-2 me-2"
                          style={{ width: '35px', height: '35px', background: '#e5e5e5', borderRadius: '50%' }} />
                        <p>My Pages</p>
                        <div className="sub-menu-link-count p-2 d-flex align-items-center"
                          style={{
                            background: '#ff5200', borderRadius: '50%', fontSize: '12px', fontWeight: '600', color: 'white',
                            width: 'auto', height: '25px'
                          }}>
                          {data.length}
                          {/* <span className="p-1">{data.length}</span> */}
                        </div>
                        <span><ChevronRightIcon /></span>
                      </div>
                      <div className="sub-menu-link mb-2" onClick={goToAddPropertyPage}>
                        <AddIcon className="p-2 me-2"
                          style={{ width: '35px', height: '35px', background: '#e5e5e5', borderRadius: '50%' }} />
                        <p>Add Property</p>
                        <span><ChevronRightIcon /></span>
                      </div>
                      <div className="sub-menu-link mb-2" onClick={goToCreateProfessionalPage}>
                        <AddIcon className="p-2 me-2"
                          style={{ width: '35px', height: '35px', background: '#e5e5e5', borderRadius: '50%' }} />
                        <p>Create Professional Page</p>
                        <span><ChevronRightIcon /></span>
                      </div>
                      <div className="sub-menu-link" onClick={doLogout}>
                        <LogoutIcon className="p-2 me-2"
                          style={{ width: '35px', height: '35px', background: '#e5e5e5', borderRadius: '50%' }} />
                        <p>Sign out</p>
                      </div>
                    </div>
                  </div>
                )}
                {isSwitchAccountMenuVisible && (
                  <div className="header-user-pic-sub-menu-wrap">
                    <div className="header-user-pic-sub-menu">
                      <Col className="d-flex align-items-center">
                        <KeyboardBackspaceIcon onClick={handleImageClick} />
                        <span className="header-select-profile ms-3">Select Profile</span>
                      </Col>
                      <div style={{ maxHeight: '475px', overflowY: 'auto' }}
                        className="switch-account-scrollbar">
                        {Array.isArray(data) && data.map((item: any, index: any) => (
                          <Col className="d-flex align-items-center switch-account-profile justify-content-between my-3"
                            key={index}>
                            <Col md={10}>
                              <img src={item.profileImageUrl ? item.profileImageUrl : Avatar} />
                              <span>{item.name}</span>
                            </Col>
                            <Col md={2}>
                              <input
                                type="radio"
                                style={{ width: '20px', height: '20px' }}
                                checked={selectedItem === null ? item.activePage : selectedItem === index}
                                onChange={() => handleRadioChange(item, index)}
                              />
                            </Col>
                          </Col>
                        ))}
                      </div>
                    </div>
                  </div>
                )}
              </div>
            )}
          </Container>
          <Modal show={showLogInDialog} onHide={handleCloseLogInDialog} backdrop="static"
            centered className="login-page-dialog-modal">
            <LoginPageDialog handleClose={handleCloseLogInDialog} />
          </Modal>
        </Navbar>
      ))
      }
    </>
  );
};
export default Header;